import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Container from '@mui/material/Container'


import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Backdrop from '@mui/material/Backdrop'

import ImageListItemBar from '@mui/material/ImageListItemBar';

import { Iinfo } from '../../types/interface'




interface Iprops {
    info: Iinfo | undefined
}
interface Ichild {
    title: string
    info: string
    id: string
    images: string[]
}
const DrinksFirstPage = (props:Iprops) => {
    const { info } = props

    const header_ref = useRef(null)

    const [loading, setLoading] = useState(true)



    const eventData = [
        {
            title:"Aqua Velva",
            info:"test1",
            id:"test1",
            images: ["/images/drinksfirstpage3.webp"]
        },
        {
            title:"French 77",
            info:"test1",
            id:"test2",
            images: ["/images/drinksfirstpage4.webp"]
        },
        {
            title:"Pegu Club",
            info:"test1",
            id:"test3",
            images: ["/images/drinksfirstpage5.webp"]
        },
        {
            title:"Japanese Highball",
            info:"test1",
            id:"test4",
            images: ["/images/drinksfirstpage6.webp"]
        }
        
    ]

    interface Inewscomponent {
        event: {
            title:string
            info:string
            id:string
            images: string []
        }[],
        info: Iinfo | undefined
    }
    const NewsComponent = (props:{
        info: Iinfo | undefined
        event: Ichild
    }) => {
        const { event, info } = props
        
      
    
        return (
            
            <Grid item xs={12} sm={6} md={3} className="flex__center-c " key={`newscomponent${event?.id}`}
                sx={{
             
                    flexDirection:"column !important",
                    justifyContent:"flex-start",
                    paddingTop: "0 !important"
                                                   
                }}
            >
                <Paper sx={{bgcolor:"#ffffff00"}} elevation={0} >
                    <Box className="flex__center-c" sx={{marginBottom:"0rem"}}>
                        <img 
                            //src={`https://${process.env.REACT_APP_CDN_URL}/${event?.images[0]}`}
                            src={`${event?.images[0]}`}
                            alt={event?.title}
                            style={{height: '15vmax', width: '15vmax', objectFit:"cover", borderRadius: "4px"}}
                        
                        />
                    </Box>
        
        
                    {/* Text Area */}
                    <Box className="flex__center-c">
        
                        {/* Title */}
                        <Typography variant='h4' color="info" paragraph>
                            {event?.title}
                        </Typography>                                                                  
                        
                    </Box>
                </Paper> 

            </Grid>
        )
    }
    
    

    return (    
        <Box component="section" className="flex__center-c header-img-fade" ref={header_ref} sx={{minHeight:"100vh", position:"relative", backgroundColor: "", gap:"2rem",p:"10rem 0"}} >

            {/* Image */}
            <img className="header-img-noblur" style={{zIndex:0}} src={info?.DrinksFirstPage?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>  

            {/* Information */}
            <Box maxWidth="90vw" zIndex={1}>

                <Typography variant="h3" sx={{mb:"1rem"}}>
                    {info?.DrinksFirstPage?.Title}
                </Typography>

                <Typography variant="h4" sx={{mb:"1rem"}}>
                    {info?.DrinksFirstPage?.Information1}
                </Typography>

               
            </Box>


    


            {/* Paper / food images */}         
            <Grid  container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"1rem !important"} zIndex={1} >
                {eventData?.map(event => {
                    
                    return(
                        <NewsComponent {...{event,info}} key={`newscomponent${event.id}`}/>
                    )
                })}



            </Grid>
                

                
       


        </Box>

        
    )
}


export default DrinksFirstPage