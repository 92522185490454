import { useState,useEffect, useRef } from 'react'


import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';

//* Input What LanguePacks are available
const languageArray = [
    "English",
    "Svenska",
    
]



const LanguageSelector = () => {

    const languageSelectorRef = useRef(null)

    const [language, setLanguage] = useState('');







    //* GSAP



    //* Check language    
    useEffect(() => {
       
        

        const currentLang = localStorage.getItem("language");

        if(!currentLang) {
            setLanguage("Svenska")
            return
        }        
        setLanguage(currentLang)  
            

        return () => {      
        }
    }, [])

    
    const LanguageHandler = (event: SelectChangeEvent) => {        
        setLanguage(event.target.value)
        localStorage.setItem("language",event.target.value)                       
        window.location.href = "/"   
    }

    return (
        <Box ref={languageSelectorRef} > 



            <Select
                variant="standard"
                
                inputProps={{
                    MenuProps: {                       
                        MenuListProps: {
                            sx: {
                                backgroundColor: '#80232300'
                            }
                        },
                        PaperProps: {
                            sx: {
                                backgroundColor: '#80232300',
                                boxShadow:"none"

                            }                        
                        },
                        SvgIcon: {
                            sx: {
                                color: '#ffffffff',
                                

                            }                        
                        }
                    }
                }}
                
                value={language ? language : "English" }
                onChange={LanguageHandler}
                label="Age"
                sx={{
                    '& .MuiSelect-icon' : {
                        color: "#ffffff"
                    },
                    '&:before' : {
                        borderBottom: "0px solid #ffffff00"
                    },
                    '&:after' : {
                        borderBottom: "0px solid #ffffff00"
                    },
              
              
                }}
            >
                {languageArray?.map((languageItem,i) => (
                    <MenuItem key={`language${i}`} value={languageItem}>{languageItem}</MenuItem>
                ))}
            </Select>

        </Box>
    )
}

export default LanguageSelector