import { useRef, useState } from 'react'

// Mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';


// Self Imports
import { Iinfo, IeditInfo } from '../../types/interface'







interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
}
const ContactInfo = (props:Iprops) => {
    const { info, editInfo } = props
    

    const header_ref = useRef(null)

    const [loading, setLoading] = useState(false)

    return (
        <Box component="section" className="flex__center-c header-img-fade" ref={header_ref} sx={{minHeight:"100vh", position:"relative", backgroundColor: "", gap:"2rem"}} >

            {/* Image */}
            <img className="header-img-noblur" style={{zIndex:0}} src={info?.Footer?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>  


            {/* Information */}
            <Box maxWidth="90vw" zIndex={1}>

                <Typography variant="h3" sx={{mb:"1rem"}}>
                    {info?.Contact?.Title}
                </Typography>

                <Typography variant="h4" sx={{mb:"1rem"}}>
                    {info?.Contact?.Information1}
                </Typography>
               
            </Box>


            {/* Buttons */}
            <Box id="welcomepage-buttons" className="flex__center-r" sx={{gap:"2rem"}} >
                <Button variant='contained'>
                    {info?.Contact?.Button_Reservation}
                </Button>                
            </Box>


            {/* Address / Contact / Info */}
            <Box className="flex__center-r" zIndex={1} >

                <Box border="1px solid #ffffff45" className="flex__center-c" sx={{p:"1rem", alignItems:"flex-start"}}>

                    <Typography variant='h4' >
                        {info?.Footer?.Title_1}
                    </Typography>                   

                    <Typography variant='h5' className='footer__button'>
                        {editInfo?.Footer?.Address}
                    </Typography>                   
                      
        
                
                    
                    <Typography variant='h5' className='footer__button'>
                        {editInfo?.Footer?.Email}
                    </Typography>                   
                                    
                    <Typography variant='h5' className='footer__button'>
                        {editInfo?.Footer?.Phone} 
                    </Typography>                                                       
                

                    <Typography variant='h4' sx={{mt:"1rem"}}>
                        {info?.Footer?.Title_2}
                    </Typography>                   
                                        
                    <Typography variant='h5' sx={{color: "#bbbbbb"}}>
                        {editInfo?.Footer?.Time}
                    </Typography>                   


                    <Box className="flex__center-r" sx={{mt:"1rem"}}>
                        <IconButton sx={{}} href={ editInfo?.Footer?.Instagram_Link || "" } target="_blank" rel="noopener">
                            <InstagramIcon fontSize='large' color='secondary' />
                        </IconButton>

                        <IconButton sx={{}} href={editInfo?.Footer?.Facebook_Link || "" } target="_blank" rel="noopener">
                            <FacebookIcon fontSize='large' color='secondary' />
                        </IconButton>

                        <IconButton sx={{}} href={editInfo?.Footer?.Twitter_Link || "" } target="_blank" rel="noopener">
                            <TwitterIcon fontSize='large' color='secondary' />
                        </IconButton>

                        <IconButton sx={{}} href={editInfo?.Footer?.LinkedIn_Link || "" } target="_blank" rel="noopener">
                            <LinkedInIcon fontSize='large' color='secondary' />
                        </IconButton>
                    </Box>
                    
                                
                </Box>


                <Box>

                   

                </Box>


            </Box>


            {/*   <Typography variant='h5' sx={{color: "#bbbbbb", fontSize:"15px !important"}}>
                {`${info?.Footer?.Company} Copyright © ${new Date().getFullYear()} - All rights reserved.`}
            </Typography>  
            <Typography variant='h5' sx={{color: "#bbbbbb", fontSize:"15px !important"}}>
                { `${info?.Footer?.Designed_By}` }
            </Typography>
            */}


        </Box>
    )
}

export default ContactInfo