import { useEffect, useState } from 'react';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

import { MainLoading } from '../../components'
import { ReactComponent as Nautical2 } from "../../assets/nautical2.svg";

import { Iinfo } from '../../types/interface';

interface fetchmenuinit {    
    title: string
    info: string        
    price: number
    category: string
}



interface Iprops {    
    info:Iinfo | undefined, menuprops:fetchmenuinit [], menucategoryprops:string []     
}
const MenuLeft = (props:Iprops) => {
    const { info, menuprops, menucategoryprops } = props
    

    const [loading, setLoading] = useState(true) 
    
    //* Rerender props */
    useEffect(() => {
        setMenu(ReStructureMenu(menuprops))    
        return () => {
            
        }
    }, [menuprops])

    //* Window scroll   */
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            
        }
    }, [])
    
    


    interface menuiteminit  {
        [key:string|number]:string | number
        title: string
        info: string        
        price: number
    }
   
   
   
    const ReStructureMenu = (menuArr: fetchmenuinit[]) => {    
     
        const returnObj:any = {}
        menucategoryprops?.forEach((item) => returnObj[item] = [] )
            
        for (let i = 0; i < menuprops?.length; i++) {
            const menuitem = menuprops[i];
            returnObj[menuitem?.category]?.push({
                title: menuitem?.title,
                price: menuitem?.price,
                info: menuitem?.info,
            })                                                        
        }

        return returnObj
    }
    const [menu, setMenu] = useState(ReStructureMenu(menuprops))
    
 
   
    const menuliststyle = {        
        bgcolor: "primary.main",        
        width: "60vw",     
        zIndex:2
    }
   

    return (
        <Box component="section" className="flex"  sx={{minHeight:"100vh", position:"relative", justifyContent:"flex-start",width:"100vw"}} >

            {/* Image */}          
            <img className="header-img-fixed"  src={info?.Drinks?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   



            <Box sx={menuliststyle} className="flex__center-c">

                    <Typography variant='h3' mt="10rem">
                        {info?.Drinks?.Title}
                    </Typography>

                    {menu && Object?.keys(menu)?.map( (key: string, i:number) => {
                        
                            
                        return (
                            <Grid container spacing={0} sx={{m:"2rem 0", width:"80%"}} key={`gridcontainer${i}`}> 

                                <Grid xs={12} sx={{padding:"0 1rem"}} item>
                                    <Typography variant='h4' sx={{margin:"2rem 0",color:"#af7b26", textAlign:"left"}} paragraph >
                                        {key?.split("_").join(" ")}
                                    </Typography>
                                </Grid>

                                
                                
                                {menu?.[key].map((menuitem: menuiteminit,j:number) => {
                                    return(
                                        <Grid xs={12} md={12} sx={{padding:"0 1rem", marginBottom:"1rem"}} key={`gridmenuitem${j}${i}`} item>

                                            <Box className='flex' style={{justifyContent:"space-between", padding:"0"}} >
                                                <Typography variant='h6' sx={{color:"secondary"}}  >  {menuitem?.title} </Typography>
                                                <Typography variant='subtitle1' sx={{color:"secondary"}}  >  {menuitem?.price} </Typography>
                                            </Box>

                                            <Typography variant='subtitle1' sx={{textAlign:"left",color:"secondary",fontWeight:500}}  >  {menuitem?.info} </Typography>
                                            
                                        </Grid>
                                    )
                                })}
                        
                                

                            </Grid>
                        )
                    })}


            </Box>








            {/* INFO */}

          


            {/* LOADING SCREEN */}
            {/* {loading && 
                    <MainLoading loading={loading} info={info?.MainLoading}/>
                } */}
        </Box>
    )
}

export default MenuLeft