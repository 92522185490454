import { useRef, useLayoutEffect} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'



import { Iinfo } from '../../types/interface'

gsap.registerPlugin(ScrollTrigger);


interface Iprops {
    info: Iinfo | undefined
}
const Concept = (props:Iprops) => {
    const { info } = props
    const header_ref = useRef(null)

    //* Page load => fade in main text*/
    useLayoutEffect(() => {

        const duration = 0.5

        const ctx = gsap.context(()=>{ 

            gsap.set('#concept-title',{
                autoAlpha:0,                
            })
            gsap.set('#concept-information',{
                autoAlpha:0,
                x:50,                
            })
            gsap.set('#concept-footer',{
                autoAlpha:0,
                x:50,                
            })

            ScrollTrigger.create({                   
                //start: "0 0",   
               // markers:true,                        
                onEnter: () => {
                    gsap.to('#concept-title',{
                        autoAlpha:1,
                        
                        duration: duration,
                        delay:0.5,
                        ease: 'power3.inOut'
                    })
                    gsap.to('#concept-information',{
                        autoAlpha:1,
                        x:0,
                        duration: duration,
                        delay:0.5,
                        ease: 'power3.inOut'
                    })
                    gsap.to('#concept-footer',{
                        autoAlpha:1,
                        x:0,
                        duration: duration,
                        delay:0.5,
                        ease: 'power3.inOut'
                    })
        
                }
            })                      
          
         
       /*      gsap.fromTo('#concept-title',{
                autoAlpha:0,
                
            },{
                autoAlpha:1,
                
                duration: duration,
                delay:1,
                ease: 'power3.inOut'
            })

            gsap.fromTo('#concept-information',{
                autoAlpha:0,
                x:100,
                
            },{
                autoAlpha:1,
                x:0,
                duration: duration,
                delay: 1.2,
                ease: 'power3.inOut'
            })

            gsap.fromTo('#concept-footer',{
                autoAlpha:0,
                x:100,
                
            },{
                autoAlpha:1,
                x:0,
                duration: duration,
                delay: 1.4,
                ease: 'power3.inOut'
            }) */
            
        },header_ref)
    
        return () => {
            ctx.revert()
        };
    }, [])

    return (
        <Box component="section" className="flex__center-c" ref={header_ref} sx={{minHeight:"50vh", position:"relative", backgroundColor:"primary.dark", gap:"2rem"}} >

            <Box maxWidth="90vw" >
                <Typography variant="h3" sx={{mb:"1rem"}} id="concept-title">
                    {info?.Concept?.Title}
                </Typography>

                <Typography variant="h4" sx={{mb:"1rem"}} id="concept-information">
                    {info?.Concept?.Information1}
                </Typography>

                <Typography variant="h4" id="concept-footer">
                    {info?.Concept?.Footer}
                </Typography>
            </Box>

        </Box>
    )
}

export default Concept