import { useState, useLayoutEffect, useRef }from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from "react-scroll";

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';


// REACT ICONS


// SELF IMPORTS 
import { ReactComponent as YourSvg } from '../../svg/klippan.svg'
import { LanguageSelector } from '../../components/index'
import { ReactComponent as Logo } from '../../assets/afekcii_logo.svg'
// STYLES
const NavbarStyle = {
    position: "fixed",
    left: 0, right: 0, top: 0,
    height: "80px",
    justifyContent: "space-between",  
    zIndex: 5,
    margin:"0 0.5rem"
   

}

gsap.registerPlugin(ScrollTrigger);
interface propsinit {         
    info: {
        [key: string]: any
    }
        

}
const Navbar2 = (props:any) => {    //TODO CHANGE any
    const { info }  = props  
   
    const navigate = useNavigate()
    const location = useLocation()
   
    const navbar_ref = useRef(null)
    const mobile_navbar_ref = useRef(null)
    const mobile_navbar_logo_ref = useRef(null)
    

    //* INTERFACE
    

    //* VARIABLES
    const [toggleNavbarMenu, setToggleNavbarMenu] = useState<boolean>(false)
    const [mobileNavbarStyle, setMobileNavbarStyle] = useState<any>({pointerEvents:"none",opacity:0,right:"-1000px"})
    const [spanStyle, setSpanStyle] = useState({span1:{},span2:{},span3:{}})

   
    
    //* FUNCTIONS
    const navigateClick = (location=""):any => {
        if(location){      
        navigate(location);      
        }
        setMobileNavbarStyle({pointerEvents:"none",opacity:0,right:"-1000px"})   
        setSpanStyle(prev => ({...prev,
        span1:{},
        span2:{},
        span3:{}

        })) 
        
        setToggleNavbarMenu(false)
    }
    const ToggleMobileMenu = (toggle:boolean) => {
        if(toggle){
        setMobileNavbarStyle({pointerEvents:"none",opacity:0,right:"-1000px"})   
        setSpanStyle(prev => ({...prev,
            span1:{},
            span2:{},
            span3:{}

        })) 
            
        }else{
        setMobileNavbarStyle({pointerEvents:"auto",opacity:1,right:"0"})   
        setSpanStyle(prev => ({...prev,
            span1:{transform: "rotate(45deg) translate(-3px, -1px)"},
            span2:{opacity: 0, transform: "rotate(0deg) scale(0.2, 0.2)" },
            span3:{transform: "rotate(-45deg) translate(0, -1px)" }

        })) 
        }
        setToggleNavbarMenu(!toggle)
    }
   
       


    //* GSAP+
    // Fades the navbar when scrolling down and shows when scrolling up
    useLayoutEffect(() => {

        ScrollTrigger.saveStyles(navbar_ref.current);
        let mm = gsap.matchMedia();
        mm.add("(min-width: 690px)",  () => {
           
            const ctx = gsap.context(()=>{

            const showNav = gsap.fromTo(navbar_ref.current,{
                autoAlpha: 0
            },{
                autoAlpha: 1,
                duration:0.4
            }).progress(1)

                
            
            ScrollTrigger.create({                   
                start: "top top", 
                end: "max",                  
                onUpdate: (self) => {
                    self.direction === -1 ? showNav.play() : showNav.reverse()
                }
                })                      
            },navbar_ref)
        
            return () => {
            ScrollTrigger.refresh()
            ctx.revert()
            };


        })
        mm.add("(max-width: 690px)",  () => {
            const ctx = gsap.context(()=>{

                const showNav = gsap.fromTo(mobile_navbar_logo_ref.current,{
                    autoAlpha: 0
                },{
                    autoAlpha: 1,
                    duration:0.4
                }).progress(1)

      
           
                    
                
                ScrollTrigger.create({                   
                    start: "top top", 
                    end: "max",                  
                    onUpdate: (self) => {
                        self.direction === -1 ? showNav.play() : showNav.reverse()
                  
                    }
                    })                      
                },mobile_navbar_ref)
            
                return () => {
                ScrollTrigger.refresh()
                ctx.revert()
                };
        })



        return () => {
           
            mm.revert()
            };
    }, [])
    


    //* Main Return */
    const matches = useMediaQuery('(max-width:690px)');

    const navbardata = [
        {
            link: info?.Navbar?.Navigation_0?.link,
            title: info?.Navbar?.Navigation_0?.title
        },
        {
            link: info?.Navbar?.Navigation_1?.link,
            title: info?.Navbar?.Navigation_1?.title
        },
        {
            link: info?.Navbar?.Navigation_2?.link,
            title: info?.Navbar?.Navigation_2?.title
        },
        {
            link: info?.Navbar?.Navigation_3?.link,
            title: info?.Navbar?.Navigation_3?.title
        }
    ]
    const locationPathStyle = {     
        fullColor: {
            color: "#ffffff"
        },
        fadeColor: {
            color: "#ffffffd1"
        }        
    }
    


    //* Navbar button for each navigation link 
    const NavButton = (path:string, title:string,index:number) => {           
        return(
            <div className=' flex__center-c' key={`NavButton${index}`}>
                <Button sx={{
                    '&:hover': {
                        backgroundColor: '#ffffff00',                        
                    },
                    '&:hover .MuiTypography-root': {                        
                        color:"#ffffff"
                    },
                    
                }} onClick={()=>navigate(path)} >
                    <Typography variant='h5' sx={ path === location.pathname ? locationPathStyle.fullColor : locationPathStyle.fadeColor }>{title}</Typography>   
                </Button>
              
            </div>
        )
    }


  
   
    if(matches){ /* Mobile */
        return (
            <Box  className="flex__center-r mobile-navbar" sx={NavbarStyle} ref={mobile_navbar_ref}>

                
                <Box >
                    <LanguageSelector />
                </Box>


                {/* TITLE BUTTON */}
                <Box className="flex__center-c" onClick={()=>navigate("/")} sx={{width:"50px",height:"50px"}} ref={mobile_navbar_logo_ref}>
                    {/* <h1 className='h1__font-medium' onClick={()=>navigate(info?.Navbar?.Navigation_9?.linkBistro</h1> */}
                    {/* <GiKnifeFork id='react-main-icon'/> */}
                    <Logo  width={matches ? "12vmax" : "12vmin"} height={matches ? "12vmax" : "12vmin"} className='container__icon-svg-nobg' onClick={()=>navigate("/")}/>   

                 
                </Box>
                    
                {/* Hamburger Menu */}
                <Box className='navbar-small__icon flex__center-c' onClick={()=>ToggleMobileMenu(toggleNavbarMenu)} sx={{width:"50px",height:"50px",zIndex:5}}>
                    <span style={spanStyle.span1}></span>
                    <span style={spanStyle.span2}></span>
                    <span style={spanStyle.span3}></span>               
                </Box>      
                

                {/* Mobile Navbar */}
                <Box className='navbar-small flex__center-c' style={mobileNavbarStyle} bgcolor={"primary.dark"} >                                
                    {/* <div className='navbar__icon' >
                    <Link onClick={()=>{navigateClick("/")}} className="footer__information__icon-link" to="container__header" spy={true} smooth={true}>
                        <h1 className='h1__font-medium' onClick={()=>navigate(info?.Navbar?.Navigation_9?.link)}></h1>
                    </Link>
                    </div> */}
                    {/* TITLE BUTTON */}
                    <Box onClick={()=>navigate("/")} sx={{}}>                    
                        <Logo  width={matches ? "5vmax" : "5vmin"} height={matches ? "5vmax" : "5vmin"} className='container__icon-svg-nobg' onClick={()=>navigate("/")}/>   
                    </Box>
                    
                    <Button variant="text" color='secondary'
                        onClick={()=>navigateClick(info?.Navbar?.Navigation_0?.link)}
                    >
                        <Typography variant='h4' color="secondary" >{info?.Navbar?.Navigation_0?.title}</Typography>   
                    </Button>
                    <Button variant="text" color='secondary'
                        onClick={()=>navigateClick(info?.Navbar?.Navigation_1?.link)}
                    >
                        <Typography variant='h4' color="secondary" >{info?.Navbar?.Navigation_1?.title}</Typography>   
                    </Button>
                    <Button variant="text" color='secondary'
                        onClick={()=>navigateClick(info?.Navbar?.Navigation_2?.link)}
                    >
                        <Typography variant='h4' color="secondary" >{info?.Navbar?.Navigation_2?.title}</Typography>   
                    </Button>                    
                    
                    <Button variant="outlined" color='secondary' sx={{borderWidth:"0.2rem"}}
                        onClick={()=>navigateClick(info?.Navbar?.Navigation_3?.link)}
                    >
                        <Typography variant='h4' color="secondary" >{info?.Navbar?.Navigation_3?.title}</Typography>   
                    </Button>

                    <IconButton href={info?.Footer?.Instagram_Link} target="_blank" rel="noopener" sx={{width:"50px",height:"50px"}} color='info'>
                        <InstagramIcon fontSize='large' fill='#ffffff !important' />
                    </IconButton>   

                </Box>


               

            </Box>
        )
    }else {    
        return (
            <Box className='flex__center-r desktop-navbar' ref={navbar_ref} sx={NavbarStyle}>
            
                {/* TITLE BUTTON */}
                <Box className="flex__center-c"  sx={{margin:"2rem 0 0 2rem",gap:"0.5rem"}}>              

                    <Logo  width={matches ? "5vmax" : "5vmin"} height={matches ? "5vmax" : "5vmin"} className='container__icon-svg-nobg' onClick={()=>navigate("/")}/>   


                    <LanguageSelector />

                </Box>


                {/* NAVIGATION MENU */}
                <Box className='flex__center-r' sx={{m:"2rem 2rem 0 0",gap:"1rem"}}>                
                    {navbardata?.map((navitem, i)=> (
                        NavButton(navitem.link, navitem.title,i)
                    ))}                                                                         
                </Box>


                {/* Social media icons */}
                <Box className='flex__center-r'>

                    <IconButton href={info?.Footer?.Instagram_Link} target="_blank" rel="noopener" color='info' /* sx={{width:"1.2vmax",height:"1.2vmax"}} */>
                        <InstagramIcon /* fontSize='1.2vmax'  */sx={{fontSize:"2vmax"}}  />
                    </IconButton>                        

                    <IconButton href={info?.Footer?.Facebook_Link} target="_blank" rel="noopener" color='info' /* sx={{width:"1.2vmax",height:"1.2vmax"}} */>
                        <FacebookIcon /* fontSize='1.2vmax'  */sx={{fontSize:"2vmax"}}  />
                    </IconButton>                        

                    

                </Box>
                                             
            </Box>
        )
    }
    }

    export default Navbar2