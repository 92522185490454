import { useLayoutEffect, useRef, useState} from 'react'
import { gsap } from 'gsap'


import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'



import { MainLoading } from '../../components'
import { Iinfo } from '../../types/interface'




interface Iprops {
    info: Iinfo | undefined
}
const Welcomepage = (props:Iprops) => {
    const { info } = props
    const [loading, setLoading] = useState(true)
    const header_ref = useRef(null)

    //* Page load => fade in main text*/
    useLayoutEffect(() => {

        const duration = 1

        const ctx = gsap.context(()=>{ 
        gsap.fromTo('.header-img-noblur',{
            scale: 1.2

        },{
            scale: 1,
            duration: 4,
            ease: 'linear'
        })
    
    
        gsap.fromTo('#welcomepage-title',{
            autoAlpha:0,
            y:100
        },{
            autoAlpha:1,
            y:0,
            duration: duration,
            delay:1,
            ease: 'power3.inOut'
        })

        gsap.fromTo('#welcomepage-buttons',{
            autoAlpha:0,
            y:100,
            
        },{
            autoAlpha:1,
            y:0,
            duration: duration,
            delay: 1.2,
            ease: 'power3.inOut'
        })

        gsap.fromTo('#welcomepage-other',{
            autoAlpha:0,
            y:100,
            
        },{
            autoAlpha:1,
            y:0,
            duration: duration,
            delay: 1.4,
            ease: 'power3.inOut'
        })
            
        },header_ref)
    
        return () => {
        ctx.revert()
        };
    }, [])

       


    
    
    
        



    return (
        <Box component="section" className="flex__center-c" ref={header_ref} sx={{minHeight:"100vh", position:"relative", backgroundColor: "black", gap:"2rem"}} >

            {/* Image */}
            <img className="header-img-noblur" style={{zIndex:0}} src={info?.Welcomepage?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   

            
            {/* Title */}
            <Typography id="welcomepage-title" variant='h1' sx={{zIndex:1}} color="primary.light">
                {info?.Welcomepage?.Title}
            </Typography>
            

            {/* Buttons */}
            <Box id="welcomepage-buttons" className="flex__center-r" sx={{gap:"2rem"}}>
                <Button variant='outlined' color='info' sx={{borderWidth:"0.15rem"}} >
                    {info?.Welcomepage?.Menu_Button}
                </Button>

                <Button variant="contained">
                    {info?.Welcomepage?.Reservation_Button}
                </Button>
            </Box>


            {/* Message / Other */}
            <Typography id="welcomepage-other" variant='h4' sx={{zIndex:1}} color="primary.light">
                {info?.Welcomepage?.SubTitle}
            </Typography>

           

            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }
            

            
        </Box>
    )
}

export default Welcomepage