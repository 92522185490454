import { useRef, useState, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Container from '@mui/material/Container'


import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Backdrop from '@mui/material/Backdrop'

import ImageListItemBar from '@mui/material/ImageListItemBar';

import { Iinfo } from '../../types/interface'

gsap.registerPlugin(ScrollTrigger);




interface Iprops {
    info: Iinfo | undefined
}
interface Ichild {
    title: string
    info: string
    id: string
    images: string[]
}
const MenuFirstPage = (props:Iprops) => {
    const { info } = props

    const header_ref = useRef(null)

    const [loading, setLoading] = useState(true)



    const eventData = [
        {
            title:"Appetizer",
            info:"test1",
            id:"test1",
            images: ["/images/menufirstpage3.webp"]
        },
        {
            title:"Main Course",
            info:"test1",
            id:"test2",
            images: ["/images/menufirstpage2.webp"]
        },
        {
            title:"Dessert",
            info:"test1",
            id:"test3",
            images: ["/images/menufirstpage1.webp"]
        }
    ]

    interface Inewscomponent {
        event: {
            title:string
            info:string
            id:string
            images: string []
        }[],
        info: Iinfo | undefined
    }
    const NewsComponent = (props:{
        info: Iinfo | undefined
        event: Ichild
    }) => {
        const { event, info } = props
    
      
    
        return (
            
          
                <Paper  sx={{m:"1rem"}}>
                    <Box className="" sx={{marginBottom:"1rem"}}>
                        <img 
                            //src={`https://${process.env.REACT_APP_CDN_URL}/${event?.images[0]}`}
                            src={`${event?.images[0]}`}
                            alt={event?.title}
                            style={{height: '18vmax', width: '18vmax', objectFit:"cover", borderRadius: "4px"}}
                        
                        />
                    </Box>
        
        
                    {/* Text Area */}
                    <Box className="flex__center-c">
        
                        {/* Title */}
                        <Typography variant='h4' color="secondary" paragraph>
                            {event?.title}
                        </Typography>                                                                  
                        
                    </Box>
                </Paper> 

           
        )
    }


    useLayoutEffect(() => {

        const duration = 0.5

        const ctx = gsap.context(()=>{ 

            gsap.set('#menufirstpage-title',{
                autoAlpha:0,                
            })
            gsap.set('#menufirstpage-information',{
                autoAlpha:0,
                x:50,                
            })
         
            gsap.set('#menufirstpage-button1',{
                autoAlpha:0,
                y:50,                
            })
            gsap.set('#menufirstpage-button2',{
                autoAlpha:0,
                y:50,                
            })
            gsap.set('#menufirstpage-image0',{
                autoAlpha:0,
                y:50,                
            })
            gsap.set('#menufirstpage-image1',{
                autoAlpha:0,
                y:50,                
            })
            gsap.set('#menufirstpage-image2',{
                autoAlpha:0,
                y:50,                
            })

            ScrollTrigger.create({                   
                //start: "0 0",   
                //markers:true,                        
                onEnter: () => {
                    gsap.to('#menufirstpage-title',{
                        autoAlpha:1,
                        
                        duration: duration,
                        delay:0.5,
                        ease: 'power3.inOut'
                    })
                    gsap.to('#menufirstpage-information',{
                        autoAlpha:1,
                        x:0,
                        duration: duration,
                        delay:0.5,
                        ease: 'power3.inOut'
                    })
                  
                    gsap.to('#menufirstpage-button1',{
                        autoAlpha:1,
                        y:0,
                        duration: duration,
                        delay:0.7,
                        ease: 'power3.inOut'
                    })
                    gsap.to('#menufirstpage-button2',{
                        autoAlpha:1,
                        y:0,
                        duration: duration,
                        delay:0.9,
                        ease: 'power3.inOut'
                    })
                    gsap.to('#menufirstpage-image0',{
                        autoAlpha:1,
                        y:0,
                        duration: duration,
                        delay:1,
                        ease: 'power3.inOut'
                    })
                    gsap.to('#menufirstpage-image1',{
                        autoAlpha:1,
                        y:0,
                        duration: duration,
                        delay:1.2,
                        ease: 'power3.inOut'
                    })
                    gsap.to('#menufirstpage-image2',{
                        autoAlpha:1,
                        y:0,
                        duration: duration,
                        delay:1.4,
                        ease: 'power3.inOut'
                    })
        
                }
            })                      
                         
            
        },header_ref)
    
        return () => {
            ctx.revert()
        };
    }, [])
    
    

    return (    
        <Box component="section" className="flex__center-c header-img-fade" ref={header_ref} sx={{minHeight:"100vh", position:"relative", backgroundColor: "", gap:"2rem",p:"10rem 0"}} >

            {/* Image */}
            <img className="header-img-noblur" style={{zIndex:0}} src={info?.MenuFirstPage?.Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>  

            {/* Information */}
            <Box maxWidth="90vw" zIndex={1}>
                <Typography variant="h3" sx={{mb:"1rem"}} id="menufirstpage-title">
                    {info?.MenuFirstPage?.Title}
                </Typography>

                <Typography variant="h4" sx={{mb:"1rem"}} id="menufirstpage-information">
                    {info?.MenuFirstPage?.Information1}
                </Typography>

          
            </Box>


            {/* Buttons */}
            <Box id="welcomepage-buttons" className="flex__center-r" sx={{gap:"2rem"}} >
                <Button variant='contained' id="menufirstpage-button1">
                    {info?.Welcomepage?.Menu_Button}
                </Button>

                <Button variant="contained" id="menufirstpage-button2">
                    {info?.Welcomepage?.Reservation_Button}
                </Button>
            </Box>


            {/* Paper / food images */}         
            <Grid  container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"1rem !important"} zIndex={1} >
                {eventData?.map((event,i) => {
                    
                    return(                        
                        <Grid  item xs={12} sm={6} md={4} className="flex__center-c " key={`menufirstpagecomponent${event?.id}`}
                            id={`menufirstpage-image${i}`}
                            sx={{
                        
                                flexDirection:"column !important",
                                justifyContent:"flex-start",
                                paddingTop: "0 !important"
                                                            
                            }}
                        >
                            <NewsComponent {...{event,info}} key={`newscomponent${event.id}`} />
                        </Grid>
                        
                    )
                })}



            </Grid>
                

                
       


        </Box>

        
    )
}


export default MenuFirstPage