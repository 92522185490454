import { useState, useEffect, Fragment, useCallback} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'


/* dashboard */
import { responsiveFontSizes, createTheme, ThemeProvider } from '@mui/material/styles';

//* REDUX */
import  store  from './store'
import { useDispatch } from 'react-redux'
import { GetGallery,GetMenu } from './slices/fetchReducer'

/* Self Imports  */
import {  Navbar2, Footer } from './components/index'
import { Welcomepage, Concept, MenuFirstPage, DrinksFirstPage, ContactInfo, Menu, MenuLeft, Reservation } from './containers/index'
import { Iinfo, IeditInfo } from './types/interface'




let theme = createTheme({    
    palette: {
        primary: {
            main: '#292929d8',                       
            dark: '#181818ff' ,
            light: "#ffffff",
            contrastText:"#ffffff"
        
            
        },
        secondary: {
            main: '#a92045',
            light:"#a9546b"
        },
        info: {
            main: "#ffffff"
        },
        text: {
            primary: "#fefefe",
            secondary: "#a92045"
        }
        
    },
    typography: {    
        h1: {            
            color: '#ffffff',
            textAlign: 'center',
            fontSize:"5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"5vmax",
            } 
        },
        h2: {            
            color: '#ffffff',
            textAlign: 'center',
            fontSize:"4vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"4vmax",
            } 
        },
        h3: {            
            color: '#ffffff',
            textAlign: 'center',
            fontSize:"3vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"3vmax",
            }  
        },
        h4: {            
            color: '#ffffff',
            textAlign: 'center',
            fontSize:"2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"2vmax",
            } 
        },
        h5: {            
            color: '#ffffff',
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            } 
        },
        h6: {            
            color: '#ffffff',
            textAlign: 'center',
            fontSize:"1.2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.2vmax",
            } 
        },
        subtitle1: {
            color:"#ffffff",
            textAlign: 'center',
            fontSize:"1vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1vmax",
            }  
        },
        subtitle2: {         
            color:"#ffffff",   
            textAlign: 'center',
            fontSize:"0.8vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"0.8vmax",
            } 
        },      
        button: {            
            textAlign: 'center',
            fontSize:"1vmax",
            fontWeight:"900" 
        },
     
      },
    components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              h1: 'h1',
              h2: 'h2',
              h3: 'h3',
              h4: 'h4',
              h5: 'h5',
              h6: 'h6',              
              subtitle1: 'p',
              subtitle2: 'h2',
              body1: 'span',
              body2: 'span',
            },
          },
        },
        MuiButton: {
            defaultProps: {          
                color:"secondary",   
                sx:{
                    fontSize:"1.2vmax",  
                    fontWeight:"400"
                }                                                             
            },
           
        },
        MuiTextField: {
            defaultProps: {          
                color:"info",     
                sx:{
                    input: {
                        color:"text.primary",                              
                    },                          
                    label:{
                        color:"text.primary",                             
                    },
                    fieldset: {
                        borderColor:"text.primary",
                    } 
                }        
            },
        },
        MuiMobileStepper:{
            defaultProps: {          
                color:"info",                   
                sx:{
                    '& .MuiMobileStepper-progress':{
                        color:"#ffffff"
                    }
                }
            }
        },
        MuiRadio:{
            defaultProps: {          
                color:"info",                    
                sx:{
                    '& .MuiRadio-root':{
                       // color:"#ffffff"
                    },                    
                    '& .Mui-checked':{
                        //color:"text.secondary"
                    }/* ,
                    '& .Mui-colorPrimary':{
                        color:"#ffffff"
                    },
                    '& .Mui-colorSecondary':{
                        color:"#ffffff"
                    } */
                
                }
            }
        },
        MuiPagination:{
            defaultProps: {                          
                sx:{
                   
                    '& .MuiButtonBase-root': {
                        borderRadius:"50%",
                        fontSize:"1vmax",
                        width:"2.5vmax",
                        minHeight:"2.5vmax",
                    },
                    '& .MuiSvgIcon-root': {                                                
                        width:"2.5vmax",
                        height:"2.5vmax",
                    },
                }
            }
        },
        MuiBottomNavigation:{
            defaultProps: {                          
                sx:{
                   
                    '& .Mui-selected': {
                        color:"red"                    
                    },
                }
            }
        }
    },
});
theme = responsiveFontSizes(theme);

const App = () => {
    const defaultTheme = theme;

    type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
  
    


    //* Get Info */
    interface ObjectStrings {[key:string]:string}
    interface ObjectAll {[key:string]: ObjectStrings}
    const [info, setInfo] = useState<Iinfo>()
    useEffect(() => {

        const lang = localStorage.getItem("language")        
        fetch(`/info/info_${lang ? lang.toLowerCase() : "english"}.json`)
            .then(res => res.json())
            .then((res) => {                       
                setInfo(res)
            })            
        return () => {}
    }, [])

    interface Imenu { 
        title: string
        info: string        
        price: number
        category: string
    }

    const [gallery, setGallery] = useState<string []>([])
    const [editInfo, setEditInfo] = useState<IeditInfo >()
    const [eventAll, setEventAll] = useState([])
    const [frontPageId, setFrontPageId] = useState("")
    const [menu, setMenu] = useState<Imenu []>([])
    const [menuCategory, setMenuCategory] = useState<string []>([])
    const [drinks, setDrinks] = useState<Imenu []>([])
    const [drinksCategory, setDrinksCategory] = useState<string []>([])
    const [event, setEvent] = useState({
        _id: "",
        title: "",
        info: "",
        images: [],
    })
    useEffect(() => {    

        //fetch(`/event/event.json`)
        fetch(`https://${process.env.REACT_APP_CDN_URL}/event/event.json`)
            .then(response => {return response.json()})
            .then(data => {                
                setEventAll(data?.Event)
                const tempevent =  data?.Event.filter((obj:ObjectAll) => obj._id === data?.FrontPage)                
                setEvent(tempevent[0])
                setFrontPageId(data?.FrontPage)
            })
            .catch(err=> console.log(err))

    
            dispatch(GetGallery({}))
                .unwrap()
                .then(res => {
                    
            })


        fetch(`https://${process.env.REACT_APP_CDN_URL}/gallery/gallery.json`)
            .then(response => {return response.json()})
            .then(data => {                             
               
                setGallery(data.Gallery)                
            })
            .catch(err=> console.log(err))


        //fetch(`https://${process.env.REACT_APP_CDN_URL}/info/editinfo.json`)
        fetch(`/info/editinfo.json`)
            .then(response => {return response.json()})
            .then((data) => {                                                             
                setEditInfo(data)                
            })
            .catch(err=> console.log(err))

          

      
           
        dispatch(GetMenu({}))
            .unwrap()
            .then(data => {
                data = {
                    Menu: [
                     
                        {
                            title:"Caesar Salat",
                            category: "Appetizers",
                            price: 100,
                            info: "Glazed Strips of Corn-fed Chicken Breast Parmesan | Croutons"
                        },
                        {
                            title:"Clubsandwich",
                            category: "Appetizers",
                            price: 102,
                            info: "Corn-fed Chicken Breast | Truffled Mayonnaise | Bacon | Fried Egg | Tomato | Romaine Lettuce"
                        },                        
                        {
                            title:"Surf ‘n’ Turf",
                            category: "Grill",
                            price: 560,
                            info: "Fillet of Beef | King Prawn | Veal Jus | French Beans | Potato-Butter Mash | Crumbs of Nutmeg"
                        },
                        {
                            title:"Fillet of Salmon",
                            category: "Grill",
                            price: 200,
                            info: "150g | Norway"
                        },
                        {
                            title:"Fillet of Lamb",
                            category: "Grill",
                            price: 350,
                            info: "200g | New Zealand"
                        },                        
                        {
                            title:"Pineapple & Brioche",
                            category: "Desserts",
                            price: 115,
                            info: "Grilled | Banana | Tepache | Vanilla Ice Cream"
                        },
                        {
                            title:"Vanilla Icecream",
                            category: "Desserts",
                            price: 80,
                            info: ""
                        },
                        {
                            title:"Sweet Butter Cake",
                            category: "Desserts",
                            price: 90,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        {
                            title:"Vegan Peanut Butter Cake",
                            category: "Desserts",
                            price: 150,
                            info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid"
                        },
                        
                    ],
                    Category: ["Appetizers","Grill","Desserts"]
                }
                setMenu(data?.Menu) //data?.Menu
                setMenuCategory(data?.Category) //data?.MenuCategory


                const datadrink = {
                    Menu:[
                        {
                            title:"Clover Club",
                            category: "Cocktails",
                            price: 100,
                            info: ""
                        },
                        {
                            title:"Mai Tai",
                            category: "Cocktails",
                            price: 100,
                            info: ""
                        },
                        {
                            title:"French 75",
                            category: "Cocktails",
                            price: 100,
                            info: ""
                        },
                        {
                            title:"Paloma",
                            category: "Cocktails",
                            price: 100,
                            info: ""
                        },
                        {
                            title:"Pisco Sour",
                            category: "Cocktails",
                            price: 100,
                            info: ""
                        },
                        {
                            title:"Gin Fizz",
                            category: "Cocktails",
                            price: 100,
                            info: ""
                        },
                        
                        {
                            title:"Pisco Sour Virgin",
                            category: "Mocktails",
                            price: 100,
                            info: "Non Alkoholic"
                        },
                        {
                            title:"Gin Fizz Virgin",
                            category: "Mocktails",
                            price: 100,
                            info: "Non Alkoholic",
                        },
                        
                        {
                            title:"The Big Stout",
                            category: "Beer",
                            price: 100,
                            info: "1.5 Liters of Dark Irish Stout"
                        },
                        {
                            title:"IPA",
                            category: "Beer",
                            price: 100,
                            info: ""
                        },

                        
                    ],
                    Category:["Cocktails", "Mocktails", "Beer"]
                }
                setDrinks(datadrink?.Menu) //data?.Menu
                setDrinksCategory(datadrink?.Category) //data?.MenuCategory
                
        })

        return () => {
        
        }

    }, [])

    




    return (
        <Router >

            <ThemeProvider theme={defaultTheme}>
            
                 
                    <Navbar2 info={info}/>

                   

                        
                        <Routes >                            
                            <Route  path="/" index element={
                                <Fragment>
                                    <Welcomepage info={info}  />
                                    <Concept info={info} />
                                    <MenuFirstPage info={info} />
                                    <DrinksFirstPage info={info} />
                                    <ContactInfo info={info}  editInfo={editInfo} />
                                </Fragment>
                            } />                           
                            <Route path="/menu" element={ 
                                <Menu info={info} menuprops={menu}  menucategoryprops={menuCategory}   /> 
                            }  
                            />
                            <Route path="/drinks" element={ <MenuLeft info={info} menuprops={drinks}  menucategoryprops={drinksCategory}   />   }  />
                            <Route path="/reservation" element={<Reservation info={info} />} />
                            
                        </Routes>
                
                        
              
                    
                    <Footer info={info} editInfo={editInfo} />
                    

            </ThemeProvider>

        </Router>
    )
}

export default App

