import { useRef , useLayoutEffect} from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Mui
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { Iinfo, IeditInfo } from '../../types/interface'
gsap.registerPlugin(ScrollTrigger)

const NavbarStyle = {
    position: "fixed",
    bottom: 0, left:0,right:0,
    margin:"0 auto",
    height: "80px",   
    zIndex: 5,   
    '& .MuiBreadcrumbs-separator':{
        color: "secondary.main"
    
    }
   

}






interface Iprops {
    info: Iinfo | undefined
    editInfo: IeditInfo | undefined
}
const Footer = (props:Iprops) => {
    const { info, editInfo } = props

    const header_ref = useRef(null)

    //* GSAP
    // Fades the navbar when scrolling down and shows when scrolling up
    useLayoutEffect(() => {

        ScrollTrigger.saveStyles(header_ref.current);
     
   
      
            const ctx = gsap.context(()=>{

                const showNav = gsap.fromTo(header_ref.current,{
                    autoAlpha: 0
                },{
                    autoAlpha: 1,
                    duration:0.4
                }).progress(1)

      
           
                    
                
                ScrollTrigger.create({                   
                    start: "top top", 
                    end: "max",                  
                    onUpdate: (self) => {
                        self.direction === -1 ? showNav.play() : showNav.reverse()
                  
                    }
                    })                      
            },header_ref)
            
               



        return () => {
           
            ctx.revert()
            };
    }, [])

    return (
        <Box component="section" ref={header_ref} sx={NavbarStyle} className="flex__center-r" color="text.primary">
            <Breadcrumbs >
                <Typography variant='h6' color="text.primary">{editInfo?.Footer?.Address}</Typography>
                <Typography variant='h6' color="text.primary">{editInfo?.Footer?.Phone}</Typography>
                <Typography variant='h6' color="text.primary">{editInfo?.Footer?.Email}</Typography>
            </Breadcrumbs>
        </Box> 
    )
}

export default Footer